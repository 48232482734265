"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anamneseLicenceStore = void 0;
const vue_demi_1 = require("vue-demi");
const types_1 = require("../../../../types");
exports.anamneseLicenceStore = (0, vue_demi_1.reactive)({
    state: {
        capabilityStatement: null,
        get maxQuestionnaireCount() {
            var _a, _b;
            return (_b = (_a = this.capabilityStatement) === null || _a === void 0 ? void 0 : _a.includedQuestionnaires) !== null && _b !== void 0 ? _b : 0;
        },
    },
    questionnaireInGroupProblems(questionnaires, questionnaireIndex) {
        let elmProblems = [];
        types_1.anamneseLicenceProblemChecks.questionnaireInGroupProblems(questionnaires, questionnaireIndex, elmProblems, this.state.capabilityStatement || undefined);
        return elmProblems;
    },
    elementHasProblem(elm) {
        let elmProblems = [];
        types_1.anamneseLicenceProblemChecks.elementProblems(elm, elmProblems, this.state.capabilityStatement || undefined);
        return elmProblems.length > 0;
    },
    setCapabilityStatement(capabilityStatement) {
        this.state.capabilityStatement = capabilityStatement;
    },
    isQuestionTypeAllowed(questionType) {
        if (!this.has.CUSTOM_QUESTIONS_ADVANCED_QUESITON_TYPES) {
            return !types_1.AnamneseAdvancedQuestionTypes.includes(questionType);
        }
        return true;
    },
    hasCapability(capability) {
        var _a;
        return (_a = this.state.capabilityStatement) === null || _a === void 0 ? void 0 : _a.capabilities.includes(capability);
    },
    // just a helper to make the code more readable
    // usage in template
    // <div v-if="licenceStore.has.CUSTOM_TEXT_FIELDS">
    get has() {
        let vals = Object.values(types_1.AnamneseCapability);
        return Object.fromEntries(vals.map(cap => [cap, this.hasCapability(cap)]));
    },
});
// @ts-ignore
window.anamneseLicenceStore = exports.anamneseLicenceStore;
